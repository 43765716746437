<template>
    <div class="app">
        <form class="auth-form" @keypress.enter.prevent>
            <h1>Forgot Password</h1>
            <div class="auth-site-name">afkadvantage.com</div>
            
            <div v-if="!sent" style="width: 100%;display: flex; flex-direction: column; justify-content: center; align-items: center;">
              <div class="auth-input" style="margin-bottom: 50px;">
                  <label for="inputEmail">Email Address</label>
                  <input v-model="email" type="email" placeholder="Email Address" id="inputEmail">
              </div>

              <VueRecaptcha ref="captchaChallenge" @verify="verifyCaptcha" sitekey="6LewO_4ZAAAAADFPZr2MTzje0P1Z9kq5u-7rF648"/>

              <div class="auth-input">
                  <button type="button" v-on:click="requestReset" class="button-primary-2" v-bind:disabled="isLoading">Reset Password</button>
              </div>
            </div>
            <div v-else style="margin-bottom: 20px;">
              <span><strong>An email has been sent to {{this.email}} with the password reset link.</strong> The email can take up to 5 minutes to arrive to your inbox and make sure to check the spam folder.</span>
            </div>

            <div class="auth-switch-site">Remembered your password? <router-link to="/auth/login">Sign in</router-link></div>
        </form>
    </div>
</template>

<script>
  import Api from '../../scripts/api';
  import VueRecaptcha from 'vue-recaptcha';
  import Toast from '../../scripts/toast';

  export default {
    components: { VueRecaptcha },
    data() {
      return {
        email: '',
        captcha: '',
        isLoading: false,
        sent: false
      }
    },
    methods: {
      requestReset: async function() {
        
        if(!Api.validation.validateEmail(this.email)) {
          return Toast.error('The email is invalid.');
        }
        if(!Api.validation.validateCaptcha(this.captcha)) {
          return Toast.error('Please complete the captcha.');
        }

        this.isLoading = true;
        try {

          var response = await Api.passwordRequestReset(this.email, this.captcha);
          if(response.data.success) {
            this.sent = true;
            Toast.success(`An email has been sent to '${this.email}' with the password reset link.`);
          } else {
            switch(response.data.reason) {
              case 0: Toast.error('Invalid email address provided.'); break;
              case 1: Toast.error('Invalid captcha, try again.'); break;
              default: Toast.error('Error occured while sending request, try again.')
            }
            this.sent = false;
            this.captcha = '';
            this.$refs.captchaChallenge.reset();
            this.isLoading = false;
          }

        } catch (ex) {
          console.log(ex);
          Toast.error('Error occured while sending request, try again.')
          this.captcha = '';
          this.$refs.captchaChallenge.reset();
          this.isLoading = false;
        }
      },
      verifyCaptcha: function(response) {
        this.captcha = response;
      }
    }
  };
</script>

<style scoped>
  @import '../../assets/css/auth.css';
</style>